
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { fetchDataIdo } from './actions'
import { fetchDataContractIdo } from "./fetchDataFromContractIdo"

export const useCoreIdo = (contractAddress:string) => {
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        const getIdoInfo = async () => {
            try {
                const resultInfo = await fetchDataContractIdo(contractAddress)
                dispatch(fetchDataIdo(resultInfo))
            } catch (e) {
                console.log(e)
            }
        }
        getIdoInfo();
    }, [dispatch, contractAddress])
}

export const UseIdoInfo = () => {
    const data = useSelector<AppState, AppState['idoReducer']>((state) => state.idoReducer)
    const idoInfo = data.dataIdo
    return [ idoInfo ]
}