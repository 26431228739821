import { Flex, Text } from "@baont0209/pibridge_uikit_v20";
import { FacebookIcon, TelegramIcon, TwitterIcon } from "components/Pancake-uikit/widgets/Menu/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ProjectBanner = () => {
    function handleClick(url:string){
        window.open(url)
    }
    const [windowSize, setWindowSize] = useState(window.innerWidth)

        useEffect(() => {
            const handleResize = () => {
                setWindowSize(window.innerWidth)
            }

            window.addEventListener('resize', handleResize)

            return () => window.removeEventListener('resize', handleResize)
        }, [])
    return ( 
        <Container>
            <BannerImg src={windowSize > 600 ? "/images/ido/ido_pi.png" : "/images/ido/pibridge_banner_mobile.png" } alt="banner-ido"/>
            <Flex width="100%" mt="1rem" justifyContent="center">
                <CsHeadding>PiBridge Launchpad</CsHeadding>
            </Flex>
            <ContainerSocialLink>
                <FacebookIcon width="42px" style={{cursor:"pointer"}} onClick={()=> handleClick("https://www.facebook.com/PiBridge.org/")} />
                <TwitterIcon width="42px" style={{cursor:"pointer"}} onClick={()=> handleClick("https://twitter.com/PiBridgeOrg")} />
                <TelegramIcon width="42px" style={{cursor:"pointer"}} onClick={()=> handleClick("https://t.me/PiBridgeChannel")} />
                <LogoBSC src="/images/ido/logo_bsc.png" alt="logo_bsc" onClick={()=> handleClick("https://bscscan.com/address/0x45b19b46be1b9006f388873e2c460fccc7d00f15")} />
            </ContainerSocialLink>
        </Container>
    )
}

export default ProjectBanner

const Container = styled(Flex)`
    width: 100%;
    height: auto;
    flex-direction:column;
`
const BannerImg = styled.img`
    height: 400px;
    width: auto;
    border-radius:20px;
    @media only screen and (min-width: 601px) and (max-width: 1280px){
        height: auto;
        width: 100%;
    }
    @media only screen and (max-width: 600px){
        height: auto;
        width: 100%;
        border-radius:0px;
    }
`
const ContainerSocialLink = styled(Flex)`
    width: 100%;
    justify-content: center;
    gap:15px;
    align-items: center;
    margin-top:1rem;
`
const LogoBSC = styled.img`
    width: 36px;
    height: 36px;
    cursor: pointer;
`
const CsHeadding = styled(Text)`
    font-size:50px;
    font-weight:400;
    color: #000;
    font-family:"Marcellus" !important;
    @media screen and (max-width: 600px) {
        font-size:32px;
    }
`