import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'contexts/Localization'
import useToast from 'hooks/useToast'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useWeb3React } from '@web3-react/core'
import { useIdoContract } from 'hooks/useContract'

export const useClaim = (contractAddress, onRefresh) => {
  const [requestedClaim, setRequestedClaim] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const idoContract = useIdoContract(contractAddress)
  const { account } = useWeb3React()
  const [ pendingClaimTx, setPendingClaimTx ] = useState(false)
  const handleClaim = useCallback(async () => {
    setPendingClaimTx(true)
    try {
      const tx = await callWithGasPrice(idoContract, 'claimIdo', [])
      const receipt = await tx.wait()
      if (receipt.status) {
        toastSuccess(
          t('Claim successfully'),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}/>
        )
        setRequestedClaim(true)
        setPendingClaimTx(false)
      } else {
        // user rejected tx or didn't go thru
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
        setRequestedClaim(false)
        setPendingClaimTx(false)
      }
    } catch (e) {
      console.error(e)
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setPendingClaimTx(false)
    } finally {
        onRefresh(Date.now())
        setRequestedClaim(true)
        setPendingClaimTx(false)
    }
  }, [
    idoContract,
    t,
    toastError,
    toastSuccess,
    callWithGasPrice,
    setPendingClaimTx,
    onRefresh
  ])

  useEffect(() => {
    setPendingClaimTx(false)
  }, [account])

  return { handleClaim, requestedClaim, pendingClaimTx }
}
