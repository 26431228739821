import { Button, Flex, Text } from "@baont0209/pibridge_uikit_v20"
import Container from "components/Layout/Container"
import styled from "styled-components"

export const HeaderPi = styled.div<{bgPosition: boolean}>`
  width: 100%;
  flex-direction: column;
  min-height: 1100px;
  min-height: ${({bgPosition}) => bgPosition ? '1200px' : '1100px'};
  background: url('./images/ImgPi/HeaderV2Test.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ${({bgPosition}) => bgPosition ? '-100px' : '-180px'};
  @media screen and (min-width: 2400px) {
    min-height: 1950px;
    background-position: center -240px;
    background-position: center ${({bgPosition}) => bgPosition ? '-144px' : '-240px'};
  }
  @media screen and (min-width: 2201px) and (max-width: 2400px) {
    min-height: 1750px;
    background-position: center -240px;
    background-position: center ${({bgPosition}) => bgPosition ? '-144px' : '-240px'};
  }
  @media screen and (min-width: 2001px) and (max-width: 2200px) {
    min-height: 1550px;
    background-position: center -240px;
    background-position: center ${({bgPosition}) => bgPosition ? '-144px' : '-240px'};
  }
  @media screen and (min-width: 1801px) and (max-width: 2000px) {
    min-height: 1380px;
    background-position: center -240px;
    background-position: center ${({bgPosition}) => bgPosition ? '-144px' : '-240px'};
  }
  @media screen and (min-width: 1600px) and (max-width: 1800px) {
    min-height: 1250px;
    background-position: center -220px;
    background-position: center ${({bgPosition}) => bgPosition ? '-124px' : '-220px'};
  }
  @media screen and (max-width: 1336px) {
    min-height: 1000px;
    background-position: center -200px;
    background-position: center ${({bgPosition}) => bgPosition ? '-50px' : '-200px'};
  }
  @media screen and (max-width: 1024px) {
    min-height: 530px;
    background-position: center -80px;
    background-position: center ${({bgPosition}) => bgPosition ? '16px' : '-80px'};
  }
  @media screen and (max-width: 600px) {
    background-position: center ${({bgPosition}) => bgPosition ? '110px' : '-14px'};
    background-size: ${({bgPosition}) => bgPosition ? '118%' : 'cover'};
    min-height: ${({bgPosition}) => bgPosition ? '630px' : '530px'};
    margin-bottom: 50px;
  }
  @media screen and (max-width: 450px) {
    background-position: center ${({bgPosition}) => bgPosition ? '110px' : '-14px'};
    background-size: ${({bgPosition}) => bgPosition ? '175%' : 'cover'};
    min-height: ${({bgPosition}) => bgPosition ? '690px' : '530px'};
    margin-bottom: 50px;
  }
`

export const WrapColumn = styled(Flex)<{bgPosition: boolean}>`
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 790px;
  min-height: ${({bgPosition}) => bgPosition ? '890px' : '790px'};

  @media screen and (min-width: 2400px) {
    /* min-height: 1250px; */
    min-height: ${({bgPosition}) => bgPosition ? '1300px' : '1250px'};

  }
  @media screen and (min-width: 2450px) {
    /* min-height: 1300px; */
    min-height: ${({bgPosition}) => bgPosition ? '1350px' : '1300px'};

  }
  @media screen and (min-width: 2500px) {
    /* min-height: 1350px; */
    min-height: ${({bgPosition}) => bgPosition ? '1400px' : '1350px'};

  } 
  @media screen and (min-width: 2201px) and (max-width: 2399px) {
    /* min-height: 1200px; */
    min-height: ${({bgPosition}) => bgPosition ? '1260px' : '1200px'};

  }
  @media screen and (min-width: 2001px) and (max-width: 2200px) {
    /* min-height: 1150px; */
    min-height: ${({bgPosition}) => bgPosition ? '1165px' : '1150px'};

  }
  @media screen and (min-width: 1800px) and (max-width: 2000px) {
    /* min-height: 1050px; */
    min-height: ${({bgPosition}) => bgPosition ? '1150px' : '1050px'};
  }
  @media screen and (min-width: 1600px) and (max-width: 1800px) {
    /* min-height: 950px; */
    min-height: ${({bgPosition}) => bgPosition ? '950px' : '950px'};

  }
  @media screen and (max-width: 1336px) {
    /* min-height: 850px; */
    min-height: ${({bgPosition}) => bgPosition ? '950px' : '850px'};

  }
  @media screen and (max-width: 1024px) {
    /* min-height: 745px; */
    min-height: ${({bgPosition}) => bgPosition ? '845px' : '745px'};
  }
  @media screen and (max-width: 768px) {
    /* min-height: 550px; */
    min-height: ${({bgPosition}) => bgPosition ? '650px' : '550px'};

  }
  @media screen and (max-width: 600px) {
    min-height: ${({bgPosition}) => bgPosition ? '550px' : '450px'};
  }
  @media screen and (max-width: 450px) {
    min-height: ${({bgPosition}) => bgPosition ? '620px' : '450px'};
  }
  @media screen and (max-width: 380px) {
    min-height: ${({bgPosition}) => bgPosition ? '600px' : '450px'};
  }
  @media screen and (max-width: 350px) {
    min-height: ${({bgPosition}) => bgPosition ? '550px' : '450px'};
  }
`

export const CsFlex = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 1336px) {
    margin-bottom: 150px;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 150px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 50px;
    margin-bottom: 0px;
  }
`
export const Body = styled.div<{isShowBanner: boolean}>`
  width: 100%;
  max-width: 1280px;
  min-height: 800px;
  display: flex;
  flex-direction:column;
  justify-content: center;
  margin-top: 100px;
  padding-bottom:30px;
  @media only screen and (min-width: 601px) and (max-width: 1000px){
      margin-top: 100px;
  }
  @media only screen and (min-width: 601px) and (max-width: 1280px){
      padding-left:20px;
      padding-right:20px;
  }
  @media only screen and (max-width: 600px){
      padding-left:0px;
      padding-right:0px;
      min-height: 400px;
      margin-top: 100px;
  }
`

export const RowLayout = styled(Flex)`
   @media screen and (max-width: 1024px) {
    flex-direction: column;
   }
`

export const Row = styled(Flex)`

`

export const RowLinkPc = styled(Row)`
  gap: 22px;
  /* @media screen and (max-width: 1024px) {
    display: none;
  } */
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 0px;
  }
`

export const RowLinkMobile = styled(Row)`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`

export const ColLeft = styled.div`
  flex: 2;
  padding-right: 10px;
  @media screen and (max-width: 1024px) {
    /* margin-bottom: 80px; */  
    padding-right: 0px;
  }
`

 export const ColRight = styled.div`
  flex: 1;
  padding-left: 10px;
  @media screen and (max-width: 1024px) {
    padding-left: 0px;
    margin-top: 60px;
  }
`

export const CSContainer = styled(Container)`
  margin: 0px;
  width: 100%;
  padding: 0px 80px;

  @media screen and (max-width: 768px) {
  padding: 0px 40px;
  }
  @media screen and (max-width: 600px) {
  padding: 0px 30px;
  }
  @media screen and (max-width: 350px) {
  padding: 0px 15px;
  }
  @media screen and (min-width: 1600px) and (max-width: 1800px) {
    max-width: 1600px;    
  }
  @media screen and (min-width: 1900px) and (max-width: 2400px) {
    max-width: 1800px;    
  }
  @media screen and (min-width: 2400px) {
    max-width: 2000px;    
  }
`

  export const WrapFrameNfts = styled(Flex)`
  width: 100%;
  background: url('./images/ImgPi/pi-Frame2.png') 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 20px 0px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
    background: url('./images/ImgPi/pi-Frame2Mobile.png') 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
    padding: 30px 0px;
  }
`

export const FlexNfts = styled(Flex)`
  flex-direction: column;
  width: 50%;
  justify-content: center;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

export const TextHead = styled(Text)`
  color: #494949;
  font-weight: 400;
  font-size: 17px;
  line-height: 40px;
  /* text-transform: uppercase; */
  text-align: center;
  @media screen and (min-width: 2000px) {
     font-size: 22px;
  }
  @media screen and (max-width: 600px) {
     font-size: 15px;
  }

`

export const TextHeadLink = styled(TextHead)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
     text-align: start;
  }
`

export const TextHeadLinkPi = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 150%;
    display: flex;
    align-items: center;
    margin-right: 15px;
    @media screen and (min-width: 1900px) {
      font-size: 15px;
    }
`

export const TextNumber = styled(Text)`
  font-family: 'Poppins';
  /* color: ${({theme}) => theme.colors.textColor}; */
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  color:#494949;
  @media screen and (max-width: 600px) {
    font-size: 36px;
  }
`

export const TextNumberDay = styled(TextNumber)`
  color:#494949;
  @media screen and (max-width: 600px) {
    font-size: 25px;
  }
`
export const TextLinkPiBridge = styled.a`
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  color:#494949;
  margin-right: 15px;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

export const TextLink = styled(Text)`
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  color:#494949;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  
  @media screen and (min-width: 1900px) {
        font-size: 16px;
    }
`

// Frame Event Time
export const FrameEventTime = styled(Flex)`
  width: 100%;
  background: url('./images/ImgPi/pi-Frame.png') 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 600px) {
    gap: 5px;
  }
`

export const TextCenter = styled(Text)`
  display: flex;
  align-items: center;
  color:#494949;

  @media screen and (min-width: 1900px) {
    font-size: 16px;
  }
`

// Btn
export const WrapBtn = styled(Flex)`
  @media screen and (max-width: 600px) {
    margin-top: 15px;
  }
`



export const TextTitle = styled(TextHead)`
  font-size: 20px;
  color:#494949;
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
`


export const ImgWhiteList = styled.img`
  width: 275px;
  @media screen and (max-width: 600px) {
    width: 200px;
  }
`
