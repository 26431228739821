import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 60 60" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
        <path d="M30 3.75C15.5039 3.75 3.75 15.5039 3.75 30C3.75 44.4961 15.5039 56.25 30 56.25C44.4961 56.25 56.25 44.4961 56.25 30C56.25 15.5039 44.4961 3.75 30 3.75ZM42.6152 23.5371C42.6328 23.8125 42.6328 24.0996 42.6328 24.3809C42.6328 32.9824 36.082 42.8906 24.1113 42.8906C20.4199 42.8906 16.998 41.8184 14.1152 39.9727C14.6426 40.0312 15.1465 40.0547 15.6855 40.0547C18.7324 40.0547 21.5332 39.0234 23.7656 37.2773C20.9062 37.2188 18.5039 35.3438 17.6836 32.7656C18.6855 32.9121 19.5879 32.9121 20.6191 32.6484C19.1468 32.3493 17.8235 31.5497 16.8739 30.3854C15.9244 29.2212 15.4071 27.7641 15.4102 26.2617V26.1797C16.2715 26.666 17.2852 26.9648 18.3457 27.0059C17.4542 26.4117 16.723 25.6067 16.2171 24.6623C15.7111 23.7179 15.446 22.6632 15.4453 21.5918C15.4453 20.3789 15.7617 19.2715 16.3301 18.3105C17.9643 20.3223 20.0035 21.9676 22.3152 23.1396C24.6269 24.3117 27.1594 24.9841 29.748 25.1133C28.8281 20.6895 32.1328 17.1094 36.1055 17.1094C37.9805 17.1094 39.668 17.8945 40.8574 19.1602C42.3281 18.8848 43.7344 18.334 44.9883 17.5957C44.502 19.1016 43.4824 20.373 42.1289 21.1758C43.4414 21.0352 44.707 20.6719 45.8789 20.1621C44.9941 21.4629 43.8867 22.6172 42.6152 23.5371Z" fill="#28A7E7"/>
    </Svg>
  );
};

export default Icon;
