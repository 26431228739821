

export default {
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x3d00461241240c938269B1628a8cE3EAd81c629F',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x390e58C934228D1839b5C1E7D86Df0eAA31c535b',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  pancakeRouter: {
    56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    97: '',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  airdrop: {
    97: '',
    56: '0x01017182a0ca83380e825e2763c50f44cc00873d',
  },
  memberOfShark: {
    97: '',
    56: '0x555aeb7f47635ec50a2a73586b002dd3880a4c4e',
  },
  referrals: {
    97: '',
    56: '0x2b84f27f6af42e68e0840f57d544229cf5c6e321'
  },
  ltdMigration: {
    97: '0x285cd8c957389f15CB1a01fb7475e511a51F0Fd0',
    56: '0x8c8998C921D0F3f1B768985bE6944734682e9C50'
  },
  ltdAirdop: {
    97: '0x1edA444606bF5Ce4A010E213966956F0fF7e1F5B',
    56: '0xD12903124827bA01d1b276078Ec58b1DBD7ECcf2'
  },
  ltdMarketplace: {
    97: '0xf9CC653F4Fd9F6aa13dF162bE7580b86095e350e',
    56: '0x332C897e209e57AF3E6657120D29c379ff6F83D3'
  },
  runMarketplace: {
    97: '0xa15fc570b6b0840dfb6f190e7275b03c3fe650ed',
    56: '0x631E0A2C12ec0489223B39BAc082ee416a8c7781'
  },
  bscChainId: {
    97: '0x61',
    56: '0x38'
  },
  piNFTS: {
    97: '0x11d00c1bf6f5bf352155c3e6bee676f0f56a597f',
    56: '0x78a207318c284757d4c6875637cd6c59b13fd8b9'
  },
  idoPi: {
    97: '0x9748a307e4a4517d5f2f447421afb7d90d0fd30f',
    56: '0xc8fb338b71ca15a04a1d08aab2095360e55f6195'
  },
}
