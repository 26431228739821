import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'contexts/Localization'
import useToast from 'hooks/useToast'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useIdoContract } from 'hooks/useContract'

export const usePurchase = (contractAddress, account,  onRefresh) => {
  const [requestedPurchase, setRequestedPurchase] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const idoContract = useIdoContract(contractAddress)
  const [ pendingPurchaseTx, setPendingPurchaseTx ] = useState(false)
  const handlePurchase = useCallback(async () => {
    setPendingPurchaseTx(true)
    try {
    
      const tx = await callWithGasPrice(idoContract, 'buyIdo', [])
      const receipt = await tx.wait()
      if (receipt.status) {
        toastSuccess(
          t('Purchase successfully'),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}/>
        )
        setRequestedPurchase(true)
        setPendingPurchaseTx(false)
        onRefresh(Date.now())
      } else {
        // user rejected tx or didn't go thru
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
        setRequestedPurchase(false)
        setPendingPurchaseTx(false)
      }
    } catch (e) {
      console.error('error',e)
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setPendingPurchaseTx(false)
    } finally {
        setRequestedPurchase(true)
        setPendingPurchaseTx(false)
    }
  }, [
    idoContract,
    t,
    toastError,
    toastSuccess,
    callWithGasPrice,
    setPendingPurchaseTx,
    onRefresh
  ])
  useEffect(() => {
    setPendingPurchaseTx(false)
  }, [account])
  return { handlePurchase, requestedPurchase, pendingPurchaseTx }
}
