import React from 'react'
import { useWalletModal, useModal, Button } from '@baont0209/pibridge_uikit_v20'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <CsButton onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet')}
    </CsButton>
  )
}

export default ConnectWalletButton

export const CsButton = styled(Button)`
  background-color: transparent;
  border-bottom: none;
  box-shadow: none;
  border: 1px solid ${({theme}) => theme.colors.textColor};
  border-radius: 0px;
  /* color: ${({theme}) => theme.colors.textColor}; */
  color:#494949;
  width: 180px;
  height: 50px;
  text-transform: uppercase;
  font-weight: 300;
  padding: 0px;
  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 50px;
    font-size: 12px;
  }

`

