
import BigNumber from "bignumber.js";
import { ERC20_ABI } from "config/abi/erc20";
import idoPiAbi from "config/abi/idoPi.json";
import useRefresh from "hooks/useRefresh";
import { useEffect, useState } from "react";
import multicall from "utils/multicall";


export const FetchDataUser = (tokenAddress, contractAddress, account, refresh=0) => {
    const [ dataUser, setDataUser ] = useState({
        balance:0,
        allowance:0
    })
    const { slowRefresh } = useRefresh()
    useEffect(() => {
        const fetchDataUser = async () => {
          try {
               const calls = [
                  {
                    address: tokenAddress,
                    name: 'balanceOf',
                    params: [account]
                  },
                  {
                    address: tokenAddress,
                    name: 'allowance',
                    params: [account, contractAddress]
                  },
               ]
              const [resultBalance, resultAllowance] = await multicall(ERC20_ABI, calls)
              setDataUser({
                balance:Number(new BigNumber(resultBalance).dividedBy(1e18).toString()),
                allowance: Number(resultAllowance.toString())
              })
          } catch (e) {
            console.log(e)
          }
        }
    
        if (account) {
            fetchDataUser()
        } else {
            setDataUser({
                balance:0,
                allowance:0
             })
        }
      }, [account, refresh, slowRefresh, contractAddress, tokenAddress])
  
    return { dataUser }
}

export const FetchDataUserFromContractIdo = (tokenAddress, contractAddress, account, refresh=0) => {
    const [ dataUserFromContract, setDataUserFromContract ] = useState({
        isWhitelist:false,
        isPaidIdo:false
    })
    const [ isLoading, setLoading ] = useState(false)
    const { slowRefresh } = useRefresh()
    useEffect(() => {
        const fetchDataUserFromContract = async () => {
          try {
               const calls = [
                  {
                    address: contractAddress,
                    name: 'whitelistMap',
                    params: [account]
                  },
                  {
                    address: contractAddress,
                    name: 'paidIdoMap',
                    params: [account]
                  },
               ]
              const [resultWhitelist, resultPaidIdo] = await multicall(idoPiAbi, calls)
              setDataUserFromContract({
                isWhitelist:resultWhitelist[0],
                isPaidIdo:resultPaidIdo[0]
              })
              setLoading(true)
          } catch (e) {
            console.log(e)
          } 
        }
    
        if (account) {
          fetchDataUserFromContract()
        } else {
          setDataUserFromContract({
              isWhitelist:false,
              isPaidIdo:false
             })
          setLoading(true)
        }
      }, [account, refresh, slowRefresh, contractAddress, tokenAddress])
  
    return { dataUserFromContract, isLoading }
  }

  export const GetStageList = (contractAddress, account, requestedClaim=true) => {
    const [ stageList , setStageList  ] = useState([
      {
        id:0,
        percent:0,
        startTime:0
      }
    ])
    const { slowRefresh } =useRefresh()
    useEffect(() => {
      const fetchStageList = async () => {
        try {
            const calls = []
              for( let index=0; index<10; index++){
                  calls.push(
                    {
                      address: contractAddress,
                      name: 'stageList',
                      params: [index]
                    }
                  )
              }
             
          const resultStageList = await multicall(idoPiAbi, calls)
            const result = resultStageList.map((item, key) => {
              return {
                id:Number(new BigNumber(item?.id.toString())),
                percent:Number(new BigNumber(item?.percent.toString())),
                startTime:Number(new BigNumber(item?.startTime.toString())),
              }
            })
            setStageList(result)
        } catch (e) {
          console.log(e)
        }
      }
  
      if (account) {
        fetchStageList()
      } else {
        setStageList(
          [
            {
              id:0,
              percent:0,
              startTime:0
            }
          ]
        )
      }
    }, [account, requestedClaim, contractAddress, slowRefresh])
  
    return { stageList }
  }
  
  export const GetVestingMap  = (contractAddress, account, refresh=0) => {
    const [ vestingMap, setVestingMap ] = useState(0)
    const { slowRefresh } = useRefresh()
    useEffect(() => {
      const fetchUserVestingMap = async () => {
        try {
             const calls = [
                {
                  address: contractAddress,
                  name: 'vestingMap',
                  params: [account]
                },
             ]
            const [resultVestingMap] = await multicall(idoPiAbi, calls)
            setVestingMap(Number(new BigNumber(resultVestingMap.toString())))
        } catch (e) {
          console.log(e)
        }
      }
  
      if (account) {
        fetchUserVestingMap()
      } else {
        setVestingMap(0)
      }
    }, [ account, refresh, contractAddress, slowRefresh ])
  
    return { vestingMap }
  }