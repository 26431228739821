import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 60 60" fill="none" {...props}>
        <path d="M55 30C55 16.2 43.8 5 30 5C16.2 5 5 16.2 5 30C5 42.1 13.6 52.175 25 54.5V37.5H20V30H25V23.75C25 18.925 28.925 15 33.75 15H40V22.5H35C33.625 22.5 32.5 23.625 32.5 25V30H40V37.5H32.5V54.875C45.125 53.625 55 42.975 55 30Z" fill="#3B579D"/>
    </Svg>
  );
};

export default Icon;

