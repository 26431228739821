import BigNumber from "bignumber.js";
import idoPiAbi from "config/abi/idoPi.json";
import useRefresh from "hooks/useRefresh";
import { useEffect, useState } from "react";
import multicall from "utils/multicall";
import { dataIdo } from "./types";

export const fetchDataContractIdo = async (contractAddress:string): Promise<dataIdo> => {
    try {
        const calls = [
            {
              address: contractAddress,
              name: 'startBuyTime',
              params: []
            },
            {
              address: contractAddress,
              name: 'endBuyTime',
              params: []
            },
            {
              address: contractAddress,
              name: 'tokenPerSlot',
              params: []
            },
            {
              address: contractAddress,
              name: 'totalOfSlot',
              params: []
            },
            {
              address: contractAddress,
              name: 'payAmountPerSlot',
              params: []
            },
            {
              address: contractAddress,
              name: 'limitTime',
              params: []
            },
         ]
            const [ resultStartBuyTime, resultEndBuyTime, resultTokenPerSlot, resultTotalOfSlot, resultPayAmountPerSlot, resultLimitTime] = await multicall(idoPiAbi, calls)  
            return {
                dataIdo: {
                    startBuyTime:Number(resultStartBuyTime.toString()),
                    endBuyTime:Number(resultEndBuyTime.toString()),
                    tokenPerSlot:Number(new BigNumber(resultTokenPerSlot).dividedBy(1e18).toString()),
                    totalOfSlot:Number(resultTotalOfSlot.toString()),
                    payAmountPerSlot:Number(new BigNumber(resultPayAmountPerSlot).dividedBy(1e18).toString()),
                    limitTime:Number(resultLimitTime.toString()),
                }
            }
        } catch (e) {
            console.log(e)
            
            return {
                dataIdo: {
                    startBuyTime:0,
                    endBuyTime:0,
                    tokenPerSlot:0,
                    totalOfSlot:0,
                    payAmountPerSlot:0,
                    limitTime:0
                }
            }
        }
}

export const FetchTotalSlotPaid = (contractAddress, refresh=0) => {
  const [ totalStaked, setTotalStaked ] = useState(0)
  const [ loading, setLoading ] = useState(false)
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    const fetchTotalStaked = async () => {
      try {
           const calls = [
              {
                address: contractAddress,
                name: 'totalOfPaid',
                params: []
              },
           ]
          const result = await multicall(idoPiAbi, calls)
          setTotalStaked(Number(new BigNumber(result).toString()))
          setLoading(true)
      } catch (e) {
        console.log(e)
      } 
    }

    if (contractAddress) {
      fetchTotalStaked()
    } else {
      setTotalStaked(0)
      setLoading(true)
    }
  }, [contractAddress, slowRefresh, refresh])

  return { totalStaked, loading }
}