import BigNumber from "bignumber.js";
import React from "react";
import styled from "styled-components";

import { Button, Flex, Text } from "@baont0209/pibridge_uikit_v20";
import ConnectWalletButton from "components/ConnectWalletButton";
import { AutoRenewIcon } from "components/Pancake-uikit";
import { useTranslation } from "contexts/Localization";
import Countdown, { zeroPad } from 'react-countdown';
import { FetchTotalSlotPaid } from "state/ido/fetchDataFromContractIdo";
import { FetchDataUser } from "state/ido/fetchDataUser";
import { useApprove } from "../hook/useApprove";
import { usePurchase } from "../hook/usePurchase";

interface Props {
    onRefresh?:(newValue) => void,
    refresh:number,
    startbuytime:number,
    endbuytime:number,
    totalPerSlot:number,
    totalSlot:number,
    payAmountPerSlot:number
    contractAddress:string
    tokenAddress:string
    isLoading:boolean
    dataUserFromContract:{
        isWhitelist: boolean;
        isPaidIdo: boolean;
    },
    account:string,
    limitTime:number
}

const PurchaseAction: React.FC<Props> = ({
    onRefresh, 
    refresh,
    startbuytime,
    endbuytime,
    totalPerSlot,
    totalSlot,
    payAmountPerSlot,
    contractAddress,
    tokenAddress,
    isLoading,
    dataUserFromContract,
    account,
    limitTime
}) => {
    const { t } = useTranslation()
    const currentTime = Date.now()
    const totalAmount = totalSlot*totalPerSlot
    const { totalStaked, loading } = FetchTotalSlotPaid(contractAddress)
    const isFullPool = totalStaked >= totalSlot
    const { dataUser } = FetchDataUser(tokenAddress, contractAddress, account, refresh )
    const { handleApprove, requestedApproval, pendingApprove } = useApprove(contractAddress, tokenAddress, account, onRefresh)
    const timeCanPurchase = startbuytime*1000 < currentTime && endbuytime*1000 > currentTime
    const isEnoughBalancePurchase = (new BigNumber(dataUser?.balance).isLessThan(new BigNumber(payAmountPerSlot)))
    const { handlePurchase, requestedPurchase, pendingPurchaseTx } = usePurchase(contractAddress, account, onRefresh)
    const available = totalSlot - totalStaked >= 0 ? totalSlot - totalStaked : 0
    const completeTime = endbuytime*1000 < currentTime
    const timeLimit = limitTime*1000 > currentTime
    const renderButtonPurChase = () => {
        if( limitTime*1000 > currentTime ){
            // check điều kiện whitelist
            return (
                <CsButton
                    disabled={!timeCanPurchase || isFullPool || pendingPurchaseTx || isEnoughBalancePurchase || dataUserFromContract?.isPaidIdo === true || dataUserFromContract?.isWhitelist === false}  
                    onClick={handlePurchase}
                    endIcon={pendingPurchaseTx ? <AutoRenewIcon spin color="textDisable" /> : null}
                >
                    { dataUserFromContract?.isPaidIdo === true ?
                        "Purchased"
                    :
                        "Purchase"
                    }
                                        
                </CsButton>
            )
        }
        if ( limitTime*1000 < currentTime) {
            // không check điều kiện whitelist
            return (
                <CsButton
                    disabled={!timeCanPurchase || isFullPool || pendingPurchaseTx || isEnoughBalancePurchase || dataUserFromContract?.isPaidIdo === true}  
                    onClick={handlePurchase}
                    endIcon={pendingPurchaseTx ? <AutoRenewIcon spin color="textDisable" /> : null}
                >
                    { dataUserFromContract?.isPaidIdo === true ?
                        "Purchased"
                    :
                        "Purchase"
                    }
                                        
                </CsButton>
            )
        }
        return null
    }
    const renderButtonApprove = () => {
        if( limitTime*1000 > currentTime ){
            // check điều kiện whitelist
            return (
                <CsButton
                    disabled={!timeCanPurchase || isFullPool || pendingApprove || isEnoughBalancePurchase || dataUserFromContract?.isWhitelist === false }
                    onClick={handleApprove}
                    endIcon={pendingApprove ? <AutoRenewIcon spin color="textDisable" /> : null}

                >
                    Approve  
                </CsButton>
            )
        }
        if ( limitTime*1000 < currentTime) {
            // không check điều kiện whitelist
            return (
                <CsButton
                    disabled={!timeCanPurchase || isFullPool || pendingApprove || isEnoughBalancePurchase }
                    onClick={handleApprove}
                    endIcon={pendingApprove ? <AutoRenewIcon spin color="textDisable" /> : null}

                >
                    Approve  
                </CsButton>
            )
        }
        return null
    }
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return ( 
                <WrapListTime>
                    <Card flexDirection="column" justifyContent="center">
                        <StyCountDown>
                            <TextTime color="#494949">{zeroPad(days)}</TextTime>
                        </StyCountDown>
                        <TextTimeLetter color='#000000' mt="0.5rem" textAlign="center">{t("days")}</TextTimeLetter>
                    </Card>
                    <Card flexDirection="column" justifyContent="center">
                        <StyCountDown>
                            <TextTime color="#494949">{zeroPad(hours)}</TextTime>
                        </StyCountDown>
                        <TextTimeLetter color='#000000' mt="0.5rem" textAlign="center">{t("hours")}</TextTimeLetter>
                    </Card>
                    <Card flexDirection="column" justifyContent="center">
                        <StyCountDown>
                            <TextTime color="#494949">{zeroPad(minutes)}</TextTime>
                        </StyCountDown>
                        <TextTimeLetter color='#000000'  mt="0.5rem" textAlign="center">{t("mins")}</TextTimeLetter>
                    </Card>
                    <Card flexDirection="column" justifyContent="center">
                        <StyCountDown>
                            <TextTime color="#494949">{zeroPad(seconds)}</TextTime>
                        </StyCountDown>
                        <TextTimeLetter color='#000000' mt="0.5rem" textAlign="center">{t("secs")}</TextTimeLetter>
                    </Card>
                </WrapListTime>
            )
        }
        

        return (
            <WrapListTime>
                <Card flexDirection="column" justifyContent="center">
                    <StyCountDown>
                        <TextTime color="#494949">{zeroPad(days)}</TextTime>
                    </StyCountDown>
                    <TextTimeLetter color='#000000' mt="0.5rem" textAlign="center">{t("days")}</TextTimeLetter>
                </Card>
                <Card flexDirection="column" justifyContent="center">
                    <StyCountDown>
                        <TextTime color="#494949">{zeroPad(hours)}</TextTime>
                    </StyCountDown>
                    <TextTimeLetter color='#000000' mt="0.5rem" textAlign="center">{t("hours")}</TextTimeLetter>
                </Card>
                <Card flexDirection="column" justifyContent="center">
                    <StyCountDown>
                        <TextTime color="#494949">{zeroPad(minutes)}</TextTime>
                    </StyCountDown>
                    <TextTimeLetter color='#000000'  mt="0.5rem" textAlign="center">{t("mins")}</TextTimeLetter>
                </Card>
                <Card flexDirection="column" justifyContent="center">
                    <StyCountDown>
                        <TextTime color="#494949">{zeroPad(seconds)}</TextTime>
                    </StyCountDown>
                    <TextTimeLetter color='#000000' mt="0.5rem" textAlign="center">{t("secs")}</TextTimeLetter>
                </Card>
            </WrapListTime>
        )
    }

    return (
        <Container>
            <Col>
                <CsText>PiBridge is a hybrid all-in-one financial application platform combining both DEX and CEX models with multi-chain & cross-chain AMM, farming, lending, fundraising platform (launchpad), Event Tickets, Marketplace, Prediction, NFT, and more features. All products will be developed with Pi Network as the base.</CsText>
            </Col>
            <CsCol>
                <ContainerProjectInfo isHiddenCountdown={completeTime}>
                    <CsContainerTime>
                       <CsCol50>
                            <CsRow>
                                <CsTextTime>Presale start-time</CsTextTime>
                                <CsTextTime>(UTC)</CsTextTime>
                            </CsRow>
                            <CsContainerPresale>
                                <CsTextTime>06:00:00 AM - </CsTextTime>
                                <CsTextTimeSecond>28/12/2022</CsTextTimeSecond>
                            </CsContainerPresale>
                       </CsCol50>
                       <CsCol50>
                            <CsRow>
                                <CsTextTime>Presale end-time</CsTextTime>
                                <CsTextTime>(UTC)</CsTextTime>
                            </CsRow>
                            <CsContainerPresale>
                                <CsTextTime>03:00:00 AM - </CsTextTime>
                                <CsTextTimeSecond>30/12/2022</CsTextTimeSecond>
                            </CsContainerPresale>
                       </CsCol50>
                    </CsContainerTime>
                    <ContainerCountdown>
                        { endbuytime*1000 > currentTime &&
                            <> 
                                { startbuytime*1000 > currentTime ?
                                <>
                                        <Text>Starts-in</Text>
                                        <Countdown zeroPadTime={2} date={new Date(startbuytime*1000)}  renderer={renderCountdown}/>
                                </>
                                :
                                    <>
                                        <Text>Ends-in</Text>
                                        <Countdown zeroPadTime={2} date={new Date(endbuytime*1000)}  renderer={renderCountdown}/>
                                    </>
                                }
                            </>
                        }
                    </ContainerCountdown>
                    <ContainerInfo>
                        <Col50>
                            <Row>
                                <Text>Total:</Text>
                                <Text>30,000,000 PIB</Text>
                            </Row>
                            <Row>
                                <Text>Allowance:</Text>
                                <Text>{totalSlot.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} slot</Text>
                            </Row>
                            <Row>
                                <Text>Purchased:</Text>
                                <Text>{totalStaked} slot</Text>
                            </Row>
                            <Row>
                                <Text>Available:</Text>
                                <Text>{available.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} slot</Text>
                            </Row>
                        </Col50>
                        <Col50>
                            <Row>
                                <Text>Token name:</Text>
                                <Text>Pi Bridge</Text>
                            </Row>
                            <Row>
                                <Text>Symbol:</Text>
                                <Text>PIB</Text>
                            </Row>
                            <Row>
                                <Text>Allocating per slot:</Text>
                                <Text>100 USDT ~ 10,000 PIB</Text>
                            </Row>
                            <Row>
                                <CsRow>
                                    <CsTextTime>Public start-time</CsTextTime>
                                    <CsTextTime>(UTC)</CsTextTime>
                                </CsRow>
                                <CsContainerPresale>
                                    <CsTextTime>08:00:00 AM - </CsTextTime>
                                    <CsTextTimeSecond>28/12/2022</CsTextTimeSecond>
                                </CsContainerPresale>
                            </Row>
                        </Col50>
                    </ContainerInfo>
                    <Flex mt="1rem" width="100%" justifyContent="center">
                        { account ?
                            <>
                                { dataUser?.allowance === 0 ?
                                    // render button approve
                                    renderButtonApprove()
                                :
                                    // render button purchase
                                    renderButtonPurChase()
                                }
                            </>
                        :
                            <CsConnectWallet/>
                        }
                        
                    </Flex>
                    { ( isLoading && loading ) &&
                        <Flex width="100%" justifyContent="center" alignItems='center' mt="10px" flexDirection="column">
                            { ( dataUserFromContract?.isWhitelist === false && isLoading && account && timeLimit && dataUserFromContract?.isPaidIdo === false ) &&
                                <Text color="#FD6969">{t("You are not in whitelist")}</Text>
                            }
                            { (isEnoughBalancePurchase && dataUserFromContract?.isWhitelist === true  && dataUserFromContract?.isPaidIdo === false && timeLimit ) &&
                                <Text color="#FD6969">{t("Your USDT balance is not enough")}</Text>
                            }
                        
                            { (isFullPool && loading ) &&
                                <Text color="#FD6969">{t("Pool is full")}</Text>
                            }
                            { dataUserFromContract?.isPaidIdo === true &&
                                <Text color="#2DB399">{t("Your purchase was successfully")}</Text>
                            }
                            { (isEnoughBalancePurchase && dataUserFromContract?.isPaidIdo === false && !timeLimit && account ) &&
                                <Text color="#FD6969">{t("Your USDT balance is not enough")}</Text>
                            }
                        </Flex>
                    }
                    { !account && 
                        <Flex width="100%" justifyContent="center" alignItems='center' flexDirection="column">
                            <Text color="#FD6969">{t("Connect your wallet to continue")}</Text>
                        </Flex>
                    }
                
                </ContainerProjectInfo>
            </CsCol>
        </Container>
    )
}

export default PurchaseAction

const ContainerCountdown = styled(Flex)`
    width: 100%;
    flex-direction:column;
    ${Text}{
        width: 100%;
        text-align: center;
        font-size:20px;
        text-transform: uppercase;
    }
`
const Container = styled(Flex)`
    width: 100%;
    padding-top:15px;
    height: auto;
    flex-wrap:wrap;
    justify-content:space-between;
    @media screen and (max-width: 600px) {
        width: 100%;
        padding-left:15px;
        padding-right:15px;   
    }
`
const Col = styled(Flex)`
    width: 100%;
    height: auto;
    margin-top:10px;
    @media screen and (max-width: 1280px) {
        width: 100%;
        
    }
`
const CsCol = styled(Col)`
    justify-content: center;
`
const ContainerProjectInfo = styled(Flex)<{isHiddenCountdown:boolean}>`
    height: auto;
    flex-direction:column;
    margin-top:20px;
    width: 1146.36px;
    /* height: ; */
    height: ${({isHiddenCountdown}) => isHiddenCountdown ? "340px" : "498.91px" };
    @media screen and (max-width: 600px) {
        width: 100%;
        background: url('/images/ido/frame_mobile.png') 100% 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: ${({isHiddenCountdown}) => isHiddenCountdown ? "560px" : "700px" };
        padding:20px 10px;
    }
    @media screen and (min-width: 769px) and (max-width: 1280px) {
        height: ${({isHiddenCountdown}) => isHiddenCountdown ? "340px" : "490px" };
    }
    @media screen and (min-width: 600px) and (max-width: 768px) {
        height: ${({isHiddenCountdown}) => isHiddenCountdown ? "370px" : "530px" };
    }
    background: url('/images/ido/frame.png') 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding:20px;
`
const WrapListTime = styled(Flex)`
    gap: 2rem;
    width: 100%;
    margin-top:10px;
    justify-content: center;
    @media screen and (max-width: 600px) {
        gap: 12px;
    }
`
const Card = styled(Flex)`
    border: 1px solid ${({theme}) => theme.colors.textColor};
    flex-direction: column;
    width: 100px;
    height: 106px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
        width: 70px;
        height: 80px;
    }
`

const StyCountDown = styled(Flex)`
    width: 80px;
    height: 30.5px;
    align-items:center;
    justify-content:center;
    background-repeat:no-repeat;
    background-size: auto;
`
const TextTimeLetter = styled(Text)`
    font-weight: 400;
    text-transform: uppercase;
    font-size: 20px !important;
    @media screen and (max-width: 600px) {
        font-size: 16px !important;
    }
`

const TextTime = styled(Text)`
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color:#494949;
`
const CsText = styled(Text)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 36px;
    @media screen and (max-width: 1280px) {
        text-align: justify;
    }
    @media screen and (max-width: 600px) {
        font-size: 18px;
        line-height: 26px;
        text-align: justify;
    }
`
const Col50 = styled(Flex)`
    width:48%;
    flex-direction:column;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
`
const ContainerInfo = styled(Flex)`
    width: 100%;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    /* margin-top:10px; */
`
const Row = styled(Flex)`
    width: 100%;
    margin-top:10px;
    justify-content: space-between;
`
const CsButton = styled(Button)`
    width: 254px;
    height: 60px;
    background:transparent;
    border: 1px solid #494949;
    box-shadow:none;
    text-transform: uppercase;
    color: #494949;
`
const CsConnectWallet = styled(ConnectWalletButton)`
    width: 254px;
    height: 60px;
`

const CsContainerTime = styled(Flex)`
    flex-direction:row;
    justify-content: space-between;
    align-items:center;
    flex-wrap:wrap;
    margin-bottom:0.75rem;
    margin-top:20px;
    @media screen and (max-width: 600px) {
        gap:20px;
    }
`
const CsCol50 = styled(Flex)`
    width: 48%;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
`
const CsRow = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 768px) {
        flex-direction:column;
        align-items: flex-start;
        gap:6px;
    }
`
const CsTextTime = styled.span`
    color: #494949;
`
const CsContainerPresale = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 768px) {
        flex-direction:column;
        align-items: flex-end;
        gap:6px;
    }
`
const CsTextTimeSecond = styled.span`
    color: #494949;
    margin-left:3px;
`