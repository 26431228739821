import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'



export const DEFAULT_META: PageMeta = {
  title: 'PiBridge - Together we build Pi’s world',
  description:"PiBridge is a trustless gateway that unleashes the barrier between Pi network and other blockchains."
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('PiBridge - Together we build Pi’s world')}`,
        image: './images/ImgPi/logo.png',
        description:"PiBridge is a trustless gateway that unleashes the barrier between Pi network and other blockchains."
      }
    default:
      return null
  }
} 
