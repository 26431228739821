import React from 'react'
import styled from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const CsSvg = styled(Svg)`
  fill: none;
`

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <CsSvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.97 19.19C6.43 19.19 2.75 15.51 2.75 10.97C2.75 6.43 6.43 2.75 10.97 2.75C15.51 2.75 19.19 6.43 19.19 10.97C19.19 15.51 15.51 19.19 10.97 19.19Z"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21.25 21.2499L17.14 17.1399" stroke="#494949" strokeLinecap="round" strokeLinejoin="round" />
    </CsSvg>
  )
}

export default Icon
