import { Flex, Text } from "@baont0209/pibridge_uikit_v20";
import { useTranslation } from "contexts/Localization";
import React from "react";
import { GetStageList, GetVestingMap } from "state/ido/fetchDataUser";
import styled from "styled-components";
import RowVesting from "./RowVesting";

interface Props {
    onRefresh?:(newValue) => void,
    refresh:number,
    dataUserFromContract:{
        isWhitelist: boolean;
        isPaidIdo: boolean;
    },
    account:string
    contractAddress:string
    totalPerSlot:number
}

const Invesment: React.FC<Props> = ({
    onRefresh, 
    refresh,
    dataUserFromContract,
    account,
    contractAddress,
    totalPerSlot
}) => {
    const { t } = useTranslation()
    const { stageList } = GetStageList(contractAddress, account)
    const { vestingMap:vestingIndex } = GetVestingMap(contractAddress,account, refresh )
    return (
        <Container>
            <CsFlex>
                <img src="/images/ido/revolution_img.png" alt="revolution_img"/>
            </CsFlex>
            <Flex width="100%" mt="1rem" justifyContent="center">
                <CsHeadding color="#000" textTransform="uppercase">Vesting Schedule</CsHeadding>
            </Flex>
            <HeaderTale >
                <Col25>
                    <Text>Index</Text>
                </Col25>
                <Col25>
                    <Text>Next vesting (UTC)</Text>
                </Col25>
                <Col25>
                    <Text>Amount</Text>
                </Col25>
                <Col25/>
            </HeaderTale>
            { dataUserFromContract?.isPaidIdo === false ?
                    <RowVesting
                        id={0}
                        percent={0}
                        startTimeVesting={0}
                        refresh={refresh}
                        onRefresh={onRefresh}
                        isPaidIdo={false}
                        vestingMap={0}
                        totalPerSlot={totalPerSlot}
                        account={account}
                        contractAddress={contractAddress}
                    />
                :
                    <>
                        {stageList.map((item) =>(
                            <RowVesting
                                id={item.id}
                                percent={item.percent}
                                startTimeVesting={item.startTime}
                                refresh={refresh}
                                onRefresh={onRefresh}
                                isPaidIdo={dataUserFromContract?.isPaidIdo}
                                vestingMap={vestingIndex}
                                totalPerSlot={totalPerSlot}
                                account={account}
                                contractAddress={contractAddress}
                            />
                        ))}
                    </>
                }
            
            
        </Container>
    )
}

export default Invesment

const Container = styled(Flex)`
    width: 100%;
    height: auto;
    flex-wrap:wrap;
    margin-top:1rem;
    justify-content:space-between;
    @media screen and (max-width: 600px) {
        width: 100%;
        padding-left:20px;
        padding-right:20px;   
    }
`
const HeaderTale = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items:center;
    border-bottom:1px solid #494949;
    @media screen and (max-width: 600px) {
       display: none;
    }
`
const Col25 = styled(Flex)`
    width: 25%;
    height: 80px;
    justify-content: center;
    align-items: center;
`
const CsFlex = styled(Flex)`
    width: 100%;
    margin-bottom:1rem;
    margin-top:1rem;
    justify-content: center;
    > img {
        width: 342px;
        height: auto;
    }
`
const CsHeadding = styled(Text)`
    font-size:50px;
    font-weight:400;
    font-family:"Marcellus" !important;
    @media screen and (max-width: 600px) {
        font-size:32px;
    }
`