import { MenuEntry } from 'components/Pancake-uikit'
import { ContextApi } from 'contexts/Localization/types'
import { MenuItemsType } from '@baont0209/pibridge_uikit_v20';
import { Colors } from "../Pancake-uikit/theme/types";

export interface LinkStatus {
  text: string;
  color: keyof Colors;
}

export const status = {
  LIVE: <LinkStatus>{
    text: "LIVE",
    color: "failure",
  },
  SOON: <LinkStatus>{
    text: "Coming",
    color: "warning",
  },
  NEW: <LinkStatus>{
    text: "NEW",
    color: "success",
  },
};

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => MenuItemsType[] = (t) => [
  // {
  //   label: t('Exchange'),
  //   icon: 'Swap',
  //   href: '/swap',
  //   showItemsOnMobile: true,
  //   items: [
  //     {
  //       label: t('Swap'),
  //       href: '/swap',
  //     },
  //     {
  //       label: t('Liquidity'),
  //       href: '/liquidity',
  //     },
  //   ],
  // },
  // {
  //   label: t('Earn'),
  //   href: '/farms',
  //   icon: 'Earn',
  //   items: [
  //     {
  //       label: t('Farms'),
  //       href: '/farms',
  //     },
  //     {
  //       label: t('Pools'),
  //       href: '/pools',
  //     },
  //   ],
  // },
  // {
  //   label: t('Membership'),
  //   icon: 'Membership',
  //   href: '/membership',
  //   items: [
  //     {
  //       label: t('Private Share'),
  //       href: '/privateshare/live',
  //     },
  //     {
  //       label: t('NFT Collections'),
  //       href: '/membership',
  //     },
  //     {
  //       label: t('NFT Marketplace'),
  //       href: '/nftmarketplace',
  //     },
  //     {
  //       label: t('Run Together Box'),
  //       href: '/market',
  //     },
  //   ]
  // },
  // {
  //   label: t('DIPO ®'),
  //   icon: 'Dipo',
  //   href: '/dipo/live',
  //   isBottomNav:false,
  //   showItemsOnMobile:false,
  //   items: [
  //     {
  //       label: t('DIPO®'),
  //       href: '/dipo/live',
  //     },
  //   ]
  // },
  // {
  //   label: t('More'),
  //   icon: 'More',
  //   href: '/repo',
  //   items: [
  //     {
  //       label: t('Migration'),
  //       href: '/migration',
  //     },
  //     {
  //       label: t('Repo'),
  //       href: '/repo',
  //     },
  //     {
  //       label: t('Voting'),
  //       href: '/voting',
  //     },
  //     {
  //       label: t('Airdrop'),
  //       href: '/airdrop',
  //     }
  //   ]
  // }
]

export default config 
