import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Flex, Menu as UikitMenu, Text, useModal } from '@baont0209/pibridge_uikit_v20'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { useLiveTradeTokenPriceBusd } from 'state/farms/hooks'
import { useProfile } from 'state/profile/hooks'
import styled from 'styled-components'
import PhisingWarning from 'views/HomePi/components/PhisingWarning'
import GlobalSettings from './GlobalSettings'
import config from './config'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

interface Props { 
  isOpen: true,
  setIsOpen: any
}

export const IsOpenPhisingWaringContext = createContext<Props | null>(null);

export function IsOpenProvider(props) {
  const [isOpen, setIsOpen] = useState(true);
    
  return <IsOpenPhisingWaringContext.Provider value={{isOpen, setIsOpen}} {...props} />
}


const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = useLiveTradeTokenPriceBusd().toNumber();
  const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const location = useLocation()
  const pathname = location.pathname
  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const context = useContext(IsOpenPhisingWaringContext);
  
  const handleOpenPhisingWarning = () => {
    // setIsOpen(false);
    context?.setIsOpen(false)
  }
  return (
    <>
        <UikitMenu
            userMenu={<UserMenu/>}
            isDark={isDark}
            toggleTheme={toggleTheme}
            currentLang={currentLanguage.code === "en" ? "vi" : "en"}
            langs={languageList}
            setLang={setLanguage}
            globalMenu={<GlobalSettings />}
            cakePriceUsd={cakePriceUsd}
            links={config(t)}
            activeItem={activeMenuItem?.href}
            activeSubItem={activeSubMenuItem?.href}
            linkImages="/images/ImgPi/logo.png"
            // banner={context?.isOpen && <PhisingWarning handleOpenPhisingWarning={handleOpenPhisingWarning} isOpen={context?.isOpen}/>}
            profile={{
              username: profile?.username,
              image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
              profileLink: '/profile',
              noProfileLink: '/profile',
              showPip: !profile?.username,
            }}
            {...props}
            bgColorMenu="#FFF8E7"
        />
    </>

  )
}

export default Menu
