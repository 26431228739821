import { Button, Flex, Text } from "@baont0209/pibridge_uikit_v20";
import ConnectWalletButton from "components/ConnectWalletButton";
import { AutoRenewIcon } from "components/Pancake-uikit";
import { useTranslation } from "contexts/Localization";
import React from "react";
import styled from "styled-components";
import { useClaim } from "../hook/useClaim";

interface Props {
    onRefresh?:(newValue) => void,
    id:number,
    percent:number,
    startTimeVesting:number,
    refresh:number,
    isPaidIdo:boolean,
    vestingMap:number
    totalPerSlot:number
    account:string
    contractAddress:string
}

const RowVesting: React.FC<Props> = ({
    onRefresh, 
    refresh,
    id,
    percent,
    startTimeVesting,
    isPaidIdo,
    vestingMap,
    totalPerSlot,
    account,
    contractAddress
}) => {
    const { t } = useTranslation()
    const currentTime = Date.now()
    const convertDate = (props:number) => new Date(props*1000).toLocaleString("vi-VN", {timeZone: "UTC"});
    const { handleClaim, requestedClaim, pendingClaimTx } = useClaim(contractAddress, onRefresh)
    const timeCanClaim = startTimeVesting*1000 < currentTime
    return (
        <Row>
            <Col25>
                <CsText>Index</CsText>
                { isPaidIdo ?
                    <Text>{id === 0 ? "TGE" : id}</Text>
                :
                    <Text>---</Text>
                }
                
            </Col25>
            <Col25>
                <CsText>Next vesting (UTC)</CsText>
                { isPaidIdo ?
                    <Text>{convertDate(startTimeVesting)}</Text>
                :
                    <Text>---</Text>
                }
            </Col25>
            <Col25>
                <CsText>Amount</CsText>
                { isPaidIdo ?
                    <Text>{Number(totalPerSlot*(percent/100)).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} PIB</Text>
                :
                    <Text>---</Text>
                }
            </Col25>
            <ColButtonClaim>
                    { id < vestingMap ?
                        <CsButtomClaimed
                            disabled
                        >
                            CLAIMED
                        </CsButtomClaimed>
                    :
                        <CsButtomClaim 
                            disabled={timeCanClaim === false || pendingClaimTx || isPaidIdo === false || !account}
                            onClick={handleClaim}
                            endIcon={pendingClaimTx ? <AutoRenewIcon spin color="textDisable" /> : null}
                        >
                            CLAIM
                        </CsButtomClaim>
                    }
                   
                
            </ColButtonClaim>
        </Row>
    )
}

export default RowVesting

const Row = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items:center;
    @media screen and (max-width: 600px) {
       width: 100%;
       flex-wrap:wrap;
       border-bottom: 1px solid #D0CBBE;
    }
`
const Col25 = styled(Flex)`
    width: 25%;
    height: 80px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px) {
       width: 100%;
       height: 50px;
       justify-content: space-between;
    }
`
const CsButtomClaim = styled(Button)`
    width: 150px;
    height: 50px;
    box-shadow:none;
    background:transparent;
    border: 1px solid #494949;
    text-transform: uppercase;
    color: #494949;
`
const CsText = styled(Text)`
    @media screen and (min-width: 600px) {
       display: none;
    }
`
const ColButtonClaim= styled(Flex)`
    width: 25%;
    height: 80px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px) {
       width: 100%;
       justify-content: flex-end;
    }
`

const CsConnectWallet = styled(ConnectWalletButton)`
    width: 254px;
    height: 60px;
`

const CsButtomClaimed = styled(Button)`
    width: 150px;
    height: 50px;
    box-shadow:none;
    text-transform: uppercase;
    color: #023020 !important;
`