import React from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  useModal
} from '@baont0209/pibridge_uikit_v20'
import {  UserMenu as UIKitUserMenu,
  UserMenuItem,} from 'components/Pancake-uikit'
import { useHistory } from 'react-router';
import { ChekcSellerWhitelist } from "views/AdminBoxRuntogether/hook/fetchData"
import useAuth from 'hooks/useAuth'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components';
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'



const UserMenu = () => {
  const { t } = useTranslation()
  const history = useHistory();
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const avatarSrc =  undefined
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)
  const { isWhitelist } = ChekcSellerWhitelist()
  const MovetoAdminBox = () => {
    history.push(`/adminruntogether`)
  }
  const MovetoYourNFts = () => {
    history.push(`/mynfts`)
  }
  if (!account) {
    return (
        <ConnectWalletButton scale="sm" />
    )
  }

  return (
    <UIKitUserMenu  account={account} avatarSrc={avatarSrc}>
      {/* <WalletUserMenuItem hasLowBnbBalance={hasLowBnbBalance} onPresentWalletModal={onPresentWalletModal} /> */}
        {/* <UserMenuItem as="button" onClick={MovetoYourNFts} >
          {t('My NFTs')}
        </UserMenuItem>
        { isWhitelist === true &&
            <UserMenuItem as="button" onClick={MovetoAdminBox}>
              {t('Admin Box Together')}
            </UserMenuItem>
        } */}
      <UserMenuItem  onClick={logout}>
        <CsFlex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <CsLogoutIcon/>
        </CsFlex>
      </UserMenuItem>
    </UIKitUserMenu>
  )
}

export default UserMenu


const CsFlex = styled(Flex)`
   color: #494949;
`
const CsLogoutIcon =styled(LogoutIcon)`
  fill: #494949;
`