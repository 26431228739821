

import PageFullWidth from 'components/Layout/PageFullWidth';
import tokens from 'config/constants/tokens';
import React, { useContext, useState } from 'react';

import { useWeb3React } from '@web3-react/core';
import { IsOpenPhisingWaringContext } from 'components/Menu';
import contracts from 'config/constants/contracts';
import { useCoreIdo, UseIdoInfo } from 'state/ido';
import { FetchDataUserFromContractIdo } from 'state/ido/fetchDataUser';
import { getAddress } from 'utils/addressHelpers';
import Invesment from './components/Investment';
import ProjectBanner from './components/ProjectBanner';
import PurchaseAction from './components/PurchaseAction';

import {
    Body
} from './styles';



const IDO = () => {
    const context = useContext(IsOpenPhisingWaringContext);
    const [refresh, setRefresh] = useState(0)
    function onRefresh(value){
        setRefresh(value)
    }
    const { account } = useWeb3React()

    const contractAddress = getAddress(contracts.idoPi)
    const tokenUSDT = getAddress(tokens.usdt.address)
    useCoreIdo(contractAddress)
    const [ idoInfo ] = UseIdoInfo()
    const { dataUserFromContract, isLoading } = FetchDataUserFromContractIdo(tokenUSDT, contractAddress, account, refresh)
    return (
        <PageFullWidth>
            <Body isShowBanner={context?.isOpen}>
                <ProjectBanner/>
                <PurchaseAction
                    refresh={refresh}
                    onRefresh={onRefresh}
                    startbuytime={idoInfo?.startBuyTime}
                    endbuytime={idoInfo?.endBuyTime}
                    totalPerSlot={idoInfo?.tokenPerSlot}
                    totalSlot={3000}
                    payAmountPerSlot={idoInfo?.payAmountPerSlot}
                    contractAddress={contractAddress}
                    tokenAddress={tokenUSDT}
                    isLoading={isLoading}
                    dataUserFromContract={dataUserFromContract}
                    account={account}
                    limitTime={idoInfo?.limitTime}
                />
                <Invesment
                    refresh={refresh}
                    onRefresh={onRefresh}
                    dataUserFromContract={dataUserFromContract}
                    account={account}
                    totalPerSlot={idoInfo?.tokenPerSlot}
                    contractAddress={contractAddress}
                />
            </Body>
        </PageFullWidth>
    );
};

export default IDO;
