import { createReducer } from '@reduxjs/toolkit'
import { fetchDataIdo } from "./actions"

interface globalStateIdo {
    dataIdo: {
        startBuyTime:number,
        endBuyTime:number,
        tokenPerSlot:number,
        totalOfSlot:number,
        payAmountPerSlot:number,
        limitTime:number
    }
}
export const initialState: globalStateIdo = {
    dataIdo: {
        startBuyTime:0,
        endBuyTime:0,
        tokenPerSlot:0,
        totalOfSlot:0,
        payAmountPerSlot:0,
        limitTime:0
    }
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchDataIdo, (state, action) => {
      state.dataIdo = action.payload.dataIdo
    })
)