import React, { useCallback, useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useTranslation } from 'contexts/Localization'
import useToast from 'hooks/useToast'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useERC20 } from 'hooks/useContract'

export const useApprove = ( contractAddress, tokenAddress, account, onRefresh) => {
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const contract = contractAddress;
  const token = useERC20(tokenAddress)
  const [pendingApprove, setPendingApprove] = useState(false)
  const handleApprove = useCallback(async () => {
    setPendingApprove(true)
    try {
      setRequestedApproval(true)
      const tx = await callWithGasPrice(token, 'approve', [contract, ethers.constants.MaxUint256])
      const receipt = await tx.wait()

      if (receipt.status) {
        toastSuccess(
          t('Contract enabled'),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}/>
        )
        setRequestedApproval(false)
      } else {
        // user rejected tx or didn't go thru
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
        setRequestedApproval(false)
      }
    } catch (e) {
      console.error(e)
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setPendingApprove(false)
    } finally {
        setPendingApprove(false)
        onRefresh(Date.now())
    }
  }, [
    contract,
    token,
    t,
    toastError,
    toastSuccess,
    callWithGasPrice,
    onRefresh
  ])
  useEffect(() => {
    setPendingApprove(false)
  }, [account])

  return { handleApprove, requestedApproval, pendingApprove }
}
