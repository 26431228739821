import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import { useSaveReferrer  } from 'state/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import EasterEgg from 'components/EasterEgg'
import IDO from 'views/IDO'
import ResetCSS  from './style/ResetCSS'
import GlobalStyle from './style/Global'
import Menu, { IsOpenProvider } from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'


// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
// This config is required for number formatting Membership   
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()
  useSaveReferrer()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <IsOpenProvider>
        <Menu>
          <SuspenseWithChunkError fallback={<PageLoader />}>
            <Switch>
              <Route path="/" exact>
                  <IDO />
              </Route>          
              {/* 404 */}
              <Route component={IDO}/>
            </Switch>
          </SuspenseWithChunkError>
        </Menu>
      </IsOpenProvider>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
